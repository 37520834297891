<template>
  <div>
    <!-- page header component -->
    <page-header
      name="accounts"
      icon="account"
    >
      <template #actions>
        <div class="level-item">
          <search-bar />
        </div>

        <div class="level-item">
          <b-dropdown aria-role="list">
            <b-button
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>
            <template v-if="hasPermissions('create_accounts')">
              <b-dropdown-item
                has-link
                aria-role="menuitem"
              >
                <router-link to="/accounts/add">
                  Account
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_contacts')">
              <b-dropdown-item
                has-link
                aria-role="menuitem"
              >
                <router-link to="/contacts/add">
                  Contact
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                has-link
                aria-role="menuitem"
              >
                <router-link to="/leads/add">
                  Lead
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                v-if="hasPermissions('create_leads')"
                has-link
                aria-role="menuitem"
              >
                <router-link to="/opportunities/add">
                  Opportunity
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_activities')"
              has-link
              aria-role="menuitem"
            >
              <router-link to="/activities/add">
                Activity
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <widget-collapse-drawer>
      <template #breadcrumb>
        <!-- <section class="section has-background-light"> -->
        <div class="container">
          <b-breadcrumb size="is-small">
            <b-breadcrumb-item
              tag="router-link"
              to="/profile"
            >
              Home
            </b-breadcrumb-item>
            <b-breadcrumb-item
              tag="router-link"
              to="/accounts"
              active
            >
              Accounts
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <!-- </section> -->
      </template>

      <section class="section has-background-light">
        <div class="container">
          <div class="content">
            <h2 class="subtitle is-capitalized has-text-info">
              top 10 accounts
            </h2>
          </div>
          <top-ten-accounts-by-expected-revenue @open-drilldown="openDrilldown" />
        </div>
      </section>
    </widget-collapse-drawer>

    <section class="section">
      <div class="container">
        <div class="content">
          <h2 class="subtitle is-capitalized has-text-info">
            recent accounts
          </h2>
        </div>
        <accounts-table />
      </div>
    </section>
  </div>
</template>

<script>
import { PageHeader, WidgetCollapseDrawer } from '@/components/Shared';
import { AccountsTable } from '@/components/Accounts';
import { SearchBar } from '@/components/Search';
import { TopTenAccountsByExpectedRevenue } from '@/components/Graphs';

export default {
  name: 'ListAccounts',

  components: {
    PageHeader,
    AccountsTable,
    SearchBar,
    TopTenAccountsByExpectedRevenue,
    WidgetCollapseDrawer,
  },

  mounted() {
    if (this.hasRole('ROLE_BUSINESS_ADMIN') || this.hasRole('ROLE_SALES_MANAGER')) {
      this.$store.dispatch('Users/fetchSubordinates');
      this.$store.dispatch('Graphs/fetchTeams');
    }
  },

  methods: {
    openDrilldown(value) {
      if (value.name === 'lead') {
        this.$router.push(`/leads?${new URLSearchParams(value.filters).toString()}`);
      } else {
        this.$router.push(`/opportunities?${new URLSearchParams(value.filters).toString()}`);
      }
    },
  },

};
</script>

<style lang="css" scoped></style>

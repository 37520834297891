<template>
  <validation-observer
    ref="accountsForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form
      autocomplete="off"
      @submit.prevent="handleSubmit(updateAccount)"
    >
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="false"
      />

      <!-- page header component -->
      <page-header
        name="edit account"
        icon="account"
      >
        <template #actions>
          <div class="level-item">
            <b-field>
              <b-switch
                v-model="isActive"
                type="is-info"
              >
                active
              </b-switch>
            </b-field>
          </div>

          <div class="level-item">
            <b-field>
              <b-button
                :disabled="invalid"
                native-type="submit"
                type="is-success"
                icon-left="content-save"
              >
                update account
              </b-button>
            </b-field>
          </div>
        </template>
      </page-header>

      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-three-fifths">
              <accounts-form
                :account-types="accountTypes"
                :account-statuses="accountStatuses"
              />
            </div>

            <div class="column">
              <accounts-address-information-form />

              <accounts-company-information-form
                :industries="industries"
                :no-of-employees="noOfEmployees"
              />
            </div>
          </div>

          <b-field>
            <b-button
              :disabled="invalid"
              native-type="submit"
              type="is-success"
              icon-left="content-save"
              value="update account"
            >
              update account
            </b-button>
          </b-field>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { PageHeader } from '@/components/Shared';
import {
  AccountsForm,
  AccountsAddressInformationForm,
  AccountsCompanyInformationForm,
} from '@/components/Accounts';
import AccountsMixin from '@/mixins/AccountsMixin';

export default {
  name: 'EditAccount',

  components: {
    ValidationObserver,
    AccountsForm,
    AccountsAddressInformationForm,
    AccountsCompanyInformationForm,
    PageHeader,
  },

  mixins: [AccountsMixin],

  computed: {
    isActive: {
      get() {
        return this.$store.state.Accounts.account.isActive;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_IS_ACTIVE', value);
      },
    },
  },

  beforeCreate() {
    this.$store.commit('Accounts/CLEAR_EDIT_ACCOUNT');
  },

  methods: {
    async updateAccount() {
      this.isLoading = true;
      const payload = { ...this.account };

      /*
       * If no parent account provided, set parent ID to null
       * This is triggered when a user clears the Parent Account field
       */
      if (payload.parentId === '') {
        payload.parentId = null;
      }

      try {
        if (payload.owner.id && payload.owner.ownerId) {
          await this.$store.dispatch('Accounts/updateAccountOwner', {
            accountId: this.$route.params.accountId,
            id: payload.owner.id,
            ownerId: payload.owner.ownerId,
            percentage: payload.owner.percentage,
          });
        }

        if (payload.coOwner.id && payload.coOwner.ownerId) {
          await this.$store.dispatch('Accounts/updateAccountOwner', {
            accountId: this.$route.params.accountId,
            id: payload.coOwner.id,
            ownerId: payload.coOwner.ownerId,
            percentage: payload.coOwner.percentage,
          });
        }

        if (!payload.coOwner.id && payload.coOwner.ownerId) {
          await this.$store.dispatch('Accounts/createAccountOwner', {
            accountId: this.$route.params.accountId,
            owner: payload.coOwner,
          });
        }

        if (!payload.owner.id && payload.owner.ownerId) {
          await this.$store.dispatch('Accounts/createAccountOwner', {
            accountId: this.$route.params.accountId,
            owner: payload.owner,
          });
        }

        if (!payload.owner.ownerId) {
          payload.owner = null;
        }

        if (!payload.coOwner.ownerId) {
          payload.coOwner = null;
        }

        await this.$store.dispatch('Accounts/updateAccount', {
          accountId: this.$route.params.accountId,
          account: payload,
        });
        await this.$router.push(`/accounts/${this.$route.params.accountId}/view`);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>

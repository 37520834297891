import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    isEditing: false,
    isLoading: true,
    hasError: false,
  }),

  computed: {
    ...mapGetters({
      accounts: 'Accounts/getAccounts',
      account: 'Accounts/getAccount',
      accountStatuses: 'Lookups/getAccountStatuses',
      accountTypes: 'Lookups/getAccountTypes',
      industries: 'Lookups/getIndustries',
      noOfEmployees: 'Lookups/getNoOfEmployees',
    }),

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.accountCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_ACC_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Accounts.account.customFields;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_CUSTOM_FIELDS', value);
      },
    },
  },

  watch: {
    accountTypes: {
      handler(value) {
        if (this.$route.name === 'AddAccount') {
          const hasDefaultType = value.filter(type => type.isDefault === true);

          this.$store.commit('Accounts/SET_ACCOUNT_TYPE_ID', hasDefaultType.length > 0 ? hasDefaultType[0].id : 105);
        }
      },
    },
  },

  async created() {
    try {
      await this.$store.dispatch('Settings/fetchCustomFields', 'Account');

      if (this.hasPermissions('read_accounts') && this.$route.name === 'EditAccount') {
        await this.fetchAccount(this.$route.params.accountId);
      }
      await this.mapValues();
      await this.mapFields();

      await this.fetchAccountStatuses();
      await this.fetchAccountTypes();
      await this.fetchIndustries();
      await this.fetchNoOfEmployees();
    } catch (error) {
      console.error(error);
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    if (this.$route.name === 'AddAccount' && this.$route.query.accountId) {
      this.$store.commit('Accounts/SET_ACCOUNT_PARENT_ID', this.$route.query.accountId);
      this.$store.commit('Accounts/SET_ACCOUNT_PARENT', this.$route.query.account);
    }
  },

  methods: {
    ...mapActions({
      fetchAccount: 'Accounts/fetchAccount',
      fetchAccountStatuses: 'Lookups/fetchAccountStatuses',
      fetchAccountTypes: 'Lookups/fetchAccountTypes',
      fetchIndustries: 'Lookups/fetchIndustries',
      fetchNoOfEmployees: 'Lookups/fetchNoOfEmployees',
    }),

    mapFields() {
      try {
        if (this.customFieldsList.length > 0
        && this.$route.name === 'EditAccount') {
          const customFields = [...this.customFieldsList].map((item, index) => {
            if (item.type === 'MULTI_SELECT') {
              return ({
                ...item,
                value: this.customFields[index]?.value ? this.customFields[index]?.value.split(',') : null,
              });
            }
            return ({
              ...item,
              value: this.customFields[index]?.value && this.customFields[index]?.value.split(',').length > 1
                ? this.customFields[index]?.value.split(',') : this.customFields[index]?.value || null,
            });
          });
          this.$store.commit('Settings/SET_ACC_CUSTOM_FIELDS', customFields);
        }
      } catch (error) {
        console.error(error);
      }
    },

    mapValues() {
      if (this.customFieldsList && this.customFieldsList.length > 0 && this.$route.name === 'EditAccount') {
        const fieldValues = [...this.customFieldsList].map((item) => {
          const filteredArr = [...this.customFields]
            .filter((i) => i.customFieldId === item.id);
          return ({
            customFieldId: item.id,
            customFieldEntryId: filteredArr.length > 0
              ? [...filteredArr][0].customFieldEntryId : null,
            value: filteredArr.length > 0 ? [...filteredArr][0].value : null,
          });
        });
        this.$store.commit('Accounts/SET_ACCOUNT_CUSTOM_FIELDS', fieldValues);
      }
    },
  },
};

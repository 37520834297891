<template>
  <validation-observer
    ref="accountsForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form
      autocomplete="off"
      @submit.prevent="handleSubmit(createAccount)"
    >
      <div>
        <!-- loading components -->
        <b-loading
          v-model="isLoading"
          :is-full-page="true"
        />

        <!-- page header component -->
        <page-header
          name="add account"
          icon="account"
        >
          <template #actions>
            <div class="level-item">
              <b-field>
                <b-button
                  :disabled="invalid"
                  native-type="submit"
                  type="is-success"
                  icon-left="content-save"
                >
                  create account
                </b-button>
              </b-field>
            </div>
          </template>
        </page-header>

        <section class="section">
          <div class="container">
            <div class="columns">
              <div class="column is-narrow">
                <accounts-form
                  :account-types="accountTypes"
                  :account-statuses="accountStatuses"
                />
              </div>

              <div class="column">
                <accounts-address-information-form />

                <accounts-company-information-form
                  :industries="industries"
                  :no-of-employees="noOfEmployees"
                />
              </div>
            </div>

            <b-field>
              <b-button
                :disabled="invalid"
                native-type="submit"
                type="is-success"
                icon-left="content-save"
                value="create account"
              >
                create account
              </b-button>
            </b-field>
          </div>
        </section>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { PageHeader } from '@/components/Shared';
import {
  AccountsForm,
  AccountsAddressInformationForm,
  AccountsCompanyInformationForm,
} from '@/components/Accounts';
import AccountsMixin from '@/mixins/AccountsMixin';

export default {

  name: 'CreateAccount',

  components: {
    ValidationObserver,
    PageHeader,
    AccountsForm,
    AccountsAddressInformationForm,
    AccountsCompanyInformationForm,
  },

  mixins: [AccountsMixin],

  beforeCreate() {
    this.$store.commit('Accounts/CLEAR_ACCOUNT');
  },

  methods: {
    async createAccount() {
      this.isLoading = true;
      const payload = { ...this.account };

      if (!payload.owner.ownerId) {
        payload.owner = null;
      }

      if (!payload.coOwner.ownerId) {
        payload.coOwner = null;
      }

      if (payload.companyInformation.industryId === '') {
        payload.companyInformation.industryId = 120;
      }

      try {
        const response = await this.$store.dispatch('Accounts/createAccount', payload);
        const { id } = response;
        if (this.$route.query.contactId) {
          await this.$store.dispatch('Contacts/createContactAccount', {
            contactId: this.$route.query.contactId,
            accountId: id,
          });
          await this.$router.push(`/contacts/${this.$route.query.contactId}/view`);
        }
        await this.$router.push(`/accounts/${id}/view`);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
